import React from "react"
import Layout from "@yumgmbh/gatsby-theme-yum-components/src/components/modules/Layout"
import BodyBlocks from "@yumgmbh/gatsby-theme-yum-components/src/components/technical/BodyBlocks"
import HeroBlock from "@yumgmbh/gatsby-theme-yum-components/src/components/blocks/HeroBlock"

const EventPage = ({ content, language }) => {
  let heroButtons = []

  if (content.shop_link?.cached_url?.length > 0) {
    heroButtons.push({
      link: content.shop_link,
      link_text: "Infos und Anmeldung",
    })
  }
  if (content.flyer?.filename?.length > 0) {
    heroButtons.push({
      link: content.flyer.filename,
      link_text: "Flyer Download",
    })
  }

  return (
    <Layout language={language}>
      <HeroBlock
        block={{
          image: content.image,
          kicker: content.date_string + " | " + content.location,
          headline: content.title,
          subheadline: content.subtitle,
          buttons: heroButtons,
        }}
      />
      <BodyBlocks body={content.body} />
    </Layout>
  )
}

export default EventPage
