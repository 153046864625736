import React from "react"
import Layout from "@yumgmbh/gatsby-theme-yum-components/src/components/modules/Layout"
import BodyBlocks from "@yumgmbh/gatsby-theme-yum-components/src/components/technical/BodyBlocks"
import HeroBlock from "@yumgmbh/gatsby-theme-yum-components/src/components/blocks/HeroBlock"
import SubNavBlock from "@yumgmbh/gatsby-theme-yum-components/src/components/blocks/SubNavBlock"
import Breadcrumbs from "@yumgmbh/gatsby-theme-yum-components/src/components/modules/Breadcrumbs"

const MicrositeStartPage = ({ content, subNavItems, breadcrumbs, language }) => {
  return (
    <Layout language={language}>
      <Breadcrumbs breadcrumbs={breadcrumbs}></Breadcrumbs>
      <HeroBlock
        block={{
          image: content.image,
          kicker: content.kicker,
          headline: content.headline,
          subheadline: content.subheadline,
          logo: content.logo,
          buttons: content.buttons,
        }}
      />
      <SubNavBlock block={{ subNavItems: subNavItems }} />
      <BodyBlocks body={content.body} />
    </Layout>
  )
}

export default MicrositeStartPage
