import DefaultPage from "./DefaultPage"
import EventPage from "./EventPage"
import HeroPage from "./HeroPage"
import MicrositeStartPage from "./MicrositeStartPage"
import MicrositeSubPage from "./MicrositeSubPage"
import NewsPage from "./NewsPage"
import NewsOverViewPage from "gatsby-theme-rothenberger/src/@yumgmbh/gatsby-theme-yum-components/components/page-types/NewsOverviewPage"

const PageTypesList = {
  default_page: DefaultPage,
  start_page: DefaultPage,
  product_variant_info_page: DefaultPage,
  event_page: EventPage,
  hero_page: HeroPage,
  microsite_start_page: MicrositeStartPage,
  microsite_sub_page: MicrositeSubPage,
  news_page: NewsPage,
  news_overview_page: NewsOverViewPage,
}

const PageTypes = (type) => {
  if (typeof PageTypesList[type] === "undefined") {
    return console.log("page-type not found")
  }
  return PageTypesList[type]
}

export default PageTypes
