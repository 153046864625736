import React from "react"
import Link from "@yumgmbh/gatsby-theme-yum-components/src/components/atoms/Link"

const PressInfoBlock = ({ children }) => {
  return (
    <section className="pt-4">
      <div className="mx-auto max-w-screen-2xl px-grid">
        <div className="px-4 py-4 bg-gray-50">
          <div className="flex flex-row flex-wrap">
            <div className="flex-1">{children}</div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default PressInfoBlock
